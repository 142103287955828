<template>
  <b-modal id="address-modal" hide-footer>
    <template slot="modal-title">
      <h3 class="p-0 m-0">
        {{ title }}
      </h3>
    </template>
    <form ref="form" @submit.prevent="submitAddress">
      <validation-observer v-slot="{ invalid }">
        <address-form
          :edit-address="editAddress"
          :show-kind="true"
          @changed="addressInfoChanged"
        />
        <hr>
        <b-form-row class="justify-content-center">
          <div id="btn-submit">
            <button v-if="!loading"
                    class="btn btn-primary submit-btn-width"
                    style="pointer-events: auto"
                    type="submit"
                    :disabled="invalid"
            >
              Submit Address
            </button>
          </div>

          <ct-centered-spinner v-if="loading" />
        </b-form-row>
        <b-tooltip v-if="invalid" :target="`btn-submit`" placement="top" :triggers="'hover focus'">
          Please fill out the rest of the form.
        </b-tooltip>
      </validation-observer>
    </form>
  </b-modal>
</template>

<script>
  import { ValidationObserver } from 'vee-validate'
  import { mapGetters, mapActions } from 'vuex'
  import AddressForm from '../shared/forms/AddressForm'
  import CtCenteredSpinner from '../shared/CtCenteredSpinner'

  export default {
    name: 'AddressModal',
    components: {
      AddressForm,
      ValidationObserver,
      CtCenteredSpinner,
    },
    props: {
      bus: Object,
    },
    data() {
      return {
        loading: false,
        editAddress: null,
        address: {},
      }
    },
    computed: {
      ...mapGetters(
        'account', ['account'],
      ),
      title() {
        return this.editMode ? 'Edit Address' : 'Add Address'
      },
      editMode() {
        return !!this.editAddress
      },
    },
    async mounted() {
      this.bus.$on('address', this.open)
    },
    methods: {
      ...mapActions(
        'account', [
          'updatePersonAddress',
          'createPersonAddress',
        ],
      ),
      addressInfoChanged(address) {
        this.address = address
      },
      async submitAddress() {
        this.loading = true
        // In the case where we aren't editing an existing address
        if (this.editMode) {
          await this.updateAddress()
        } else {
          await this.createAddress()
        }
        this.loading = false
      },
      async createAddress() {
        const addressCopy = { ...this.address }
        if(!addressCopy.state_province_region) addressCopy.state_province_region = "--"
        if(!addressCopy.zip_postal_code) addressCopy.zip_postal_code = "--"

        const params = { ...addressCopy, ...{ person_id: this.account.id } }
        try {
          await this.createPersonAddress(params)
          this.$bvModal.hide('address-modal')
          this.$emit('complete')
        } catch(error) {
          this.errorToast(error.response.data.error.message)
        }
      },
      async updateAddress() {
        try {
          const addressCopy = { ...this.address }
          if(!addressCopy.state_province_region) addressCopy.state_province_region = "--"
          if(!addressCopy.zip_postal_code) addressCopy.zip_postal_code = "--"

          await this.updatePersonAddress(addressCopy)
          this.$bvModal.hide('address-modal')
          this.$emit('complete')
        } catch(error) {
          this.errorToast(error.response.data.error.message)
        }
      },
      errorToast(error) {
        this.$bvToast.toast(error, { title: 'Error', variant: 'danger' })
      },
      open(address, name) {
        this.editAddress = null
        if (name === 'edit') {
          this.editAddress = address
        }
        this.$bvModal.show('address-modal')
      },
    },
  }
</script>
