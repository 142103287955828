<template>
  <b-container>
    <masthead title="Account Settings" :show-search="false" />
    <div v-if="loaded" class="container-fluid pt-4 ml-0 pl-0 pr-0">
      <b-tabs v-model="tabIndex">
        <b-tab :title="'General'">
          <ct-table :loaded="loaded" :items="[account]" :fields="ownerFields">
            <template slot="cell(actions)" slot-scope="data">
              <b-button
                v-b-modal.edit-general-info-modal
                variant="link"
                class="mr-2"
                @click="edit('generalInfo', data.item)"
              >
                Edit
              </b-button>
            </template>
          </ct-table>
        </b-tab>

        <b-tab :title="'Phones'">
          <ct-table :loaded="loaded" :items="phones" :fields="phoneFields">
            <template slot="cell(phoneNumber)" slot-scope="data">
              {{ data.item.phone_number }}
            </template>
            <template slot="cell(actions)" slot-scope="data">
              <b-button
                v-b-modal.edit-phone-modal
                variant="link"
                class="mr-2"
                @click="edit('phone', data.item)"
              >
                Edit
              </b-button>
              <b-button
                v-if="phones.length > 1"
                class="text-danger"
                variant="link"
                @click="deletePhone(data.item)"
              >
                Delete
              </b-button>
            </template>
          </ct-table>
          <b-button
            v-b-modal.add-phone-modal
            variant="primary"
            size="$mq"
            :class="$mq !== 'sm' ? 'mr-2 rounded' : ''"
            @click="create('phone')"
          >
            Add Phone
          </b-button>
        </b-tab>

        <b-tab :title="'Addresses'">
          <ct-table :loaded="loaded" :items="addresses" :fields="addressFields">
            <template slot="cell(line1)" slot-scope="data">
              {{ data.item.line1 }}
            </template>
            <template slot="cell(line2)" slot-scope="data">
              {{ data.item.line2 }}
            </template>
            <template slot="cell(line3)" slot-scope="data">
              {{ data.item.line3 }}
            </template>
            <template slot="cell(city)" slot-scope="data">
              {{ data.item.city }}
            </template>
            <template slot="cell(country)" slot-scope="data">
              {{ data.item.country }}
            </template>
            <template slot="cell(state_province_region)" slot-scope="data">
              {{ data.item.state_province_region === '--' ? null : data.item.state_province_region }}
            </template>
            <template slot="cell(zip_postal_code)" slot-scope="data">
              {{ data.item.zip_postal_code === '--' ? null : data.item.zip_postal_code }}
            </template>
            <template slot="cell(kind)" slot-scope="data">
              {{ data.item.kind === "principle" ? "principal" : data.item.kind }}
            </template>
            <template slot="cell(actions)" slot-scope="data">
              <b-button
                v-b-modal.edit-address-modal
                variant="link"
                class="mr-2"
                @click="edit('address', data.item)"
              >
                Edit
              </b-button>
              <b-button
                v-if="addresses.length > 1"
                class="text-danger"
                variant="link"
                @click="deleteAddress(data.item)"
              >
                Delete
              </b-button>
              <feather-icon
                v-if="data.item.is_known_bad_address"
                :id="'address-attention-' + data.index"
                type="alert-octagon"
                width="25"
                height="25"
                stroke="red"
              />
              <b-tooltip
                :target="'address-attention-' + data.index"
                triggers="hover"
              >
                <p>This address needs to be updated. It is no longer a valid address.</p>
              </b-tooltip>
            </template>
          </ct-table>

          <b-button
            v-b-modal.add-address-modal
            variant="primary"
            size="$mq"
            :class="$mq !== 'sm' ? 'mr-2 rounded' : ''"
            @click="create('address')"
          >
            Add Address
          </b-button>
        </b-tab>

        <b-tab :title="'Emails'">
          <ct-table :loaded="loaded" :items="emails" :fields="emailFields">
            <template slot="cell(line1)" slot-scope="data">
              {{ data.item.email_address }}
            </template>
            <template slot="cell(line1)" slot-scope="data">
              {{ data.item.kind }}
            </template>
            <template slot="cell(actions)" slot-scope="data">
              <b-button
                v-b-modal.edit-email-modal
                variant="link"
                class="mr-2"
                @click="edit('email', data.item)"
              >
                Edit
              </b-button>
              <b-button
                :disabled="disableDelete(data.item)"
                class="text-danger"
                variant="link"
                @click="deleteEmail(data.item)"
              >
                Delete
              </b-button>
            </template>
          </ct-table>

          <b-button
            v-b-modal.add-email-modal
            variant="primary"
            size="$mq"
            :class="$mq !== 'sm' ? 'mr-2 rounded' : ''"
            @click="create('email')"
          >
            Add Email
          </b-button>
        </b-tab>
      </b-tabs>
    </div>

    <phone-modal :bus="bus" @complete="complete('Thank you for updating your phone number.')" />
    <email-modal :bus="bus" @complete="complete('Thank you for updating your email.')" />
    <address-modal :bus="bus" @complete="complete('Thank you for updating your address.')" />
    <general-info-modal :bus="bus" @complete="complete('Thank you for updating your name.')" />
  </b-container>
</template>

<script>
import GeneralInfoModal from '../components/AccountSettings/GeneralInfoModal'
import AddressModal from '../components/AccountSettings/AddressModal'
import EmailModal from '../components/AccountSettings/EmailModal'
import PhoneModal from '../components/AccountSettings/PhoneModal'
import { extend } from 'vee-validate'
import { phoneNumber } from '@/directives/directives'
import { required } from 'vee-validate/dist/rules'
import { mapGetters, mapActions } from 'vuex'
import Masthead from '@/components/shared/Masthead'
import CtTable from '../components/shared/CtTable'
import FeatherIcon from '@/components/shared/FeatherIcon'

import Vue from 'vue'

extend('required', {
  ...required,
  message: '{_field_} is required',
})

export default {
  name: 'AccountSettings',
  components: {
    CtTable,
    Masthead,
    PhoneModal,
    EmailModal,
    AddressModal,
    GeneralInfoModal,
    FeatherIcon,
  },
  directives: {
    phoneNumber,
  },
  data() {
    return {
      bus: new Vue(),
      selected: {},
      newPhone: {},
      newEmail: {},
      newAddress: {},
      errors: [],
      loaded: null,
      errored: false,
      tabIndex: 0,
      addressFields: [
        { key: 'line1', sortable: false, label: 'Line 1' },
        { key: 'line2', sortable: false, label: 'Line 2' },
        { key: 'city', sortable: false, label: 'City' },
        { key: 'state_province_region', sortable: false, label: 'State' },
        { key: 'zip_postal_code', sortable: false, label: 'Zip Code' },
        { key: 'country', sortable: false, label: 'Country' },
        { key: 'kind', sortable: false, label: 'Kind' },
        { key: 'actions', sortable: false, label: '' },
      ],
      emailFields: [
        { key: 'email_address', sortable: false },
        { key: 'kind', sortable: false },
        { key: 'actions', sortable: false, label: '' },
      ],
      phoneFields: [
        { key: 'phone_number', sortable: false },
        { key: 'actions', sortable: false, label: '' },
      ],
      ownerFields: [
        { key: 'first_name', sortable: false, label: 'First Name' },
        { key: 'last_name', sortable: false, label: 'Last Name' },
        { key: 'actions', sortable: false, label: '' },
      ],
    }
  },
  computed: {
    ...mapGetters('account', [
        'account',
        'emails',
        'phones',
        'addresses',
        'primaryEmailOnlyOne',
      ]
    ),
  },
  async mounted() {
    this.loaded = false
    await this.loadAccount()
    this.checkIfThereIsAKnownBadAddress()
    this.loaded = true
  },
  methods: {
    ...mapActions('account', [
        'loadAccount',
        'deletePersonAddress',
        'deletePersonPhone',
        'deletePersonEmail',
      ],
    ),
    makeToast(variant = null, title, body) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true,
      })
    },
    view(person_object) {
      this.$router.push({
        name: 'accountSettingEditor',
        params: { personId: person_object.id },
      })
    },
    complete(message) {
      this.makeToast('primary', 'Success!', message)
    },
    async edit(name, info) {
      this.bus.$emit(name, Object.assign({}, { ...info }), 'edit')
    },
    async create(name) {
      this.bus.$emit(name)
    },
    async deletePhone(phone) {
      this.$bvModal.hide('destroyPhone')
      try {
        await this.deletePersonPhone({ phone_id: phone.id })
        this.makeToast('primary', 'Success', 'Deleted!')
      } catch {
        this.makeToast('danger', 'Error', 'Sorry this phone can not be deleted.')
      }
    },
    async deleteEmail(email) {
      this.$bvModal.hide('destroyEmail')
      try {
        await this.deletePersonEmail({ email_id: email.id })
        this.makeToast('primary', 'Success', 'Email Deleted!')
      } catch(error) {
        this.makeToast('danger', 'Error', error.response.data.error.message)
      }
    },
    async deleteAddress(address) {
      this.$bvModal.hide('destroyAddress')
      try {
        await this.deletePersonAddress({ address_id: address.id })
        this.makeToast('primary', 'Success', 'Address Deleted!')
      } catch {
        this.makeToast('danger', 'Failure', 'The address can not be deleted.')
      }
    },
    disableDelete(email) {
      return this.emails.length <= 1 || ((email.kind === 'primary') && this.primaryEmailOnlyOne)
    },
    checkIfThereIsAKnownBadAddress() {
      this.addresses.forEach((address) => {
        if (address.is_known_bad_address) {
          this.tabIndex = 2
          this.edit('address', address)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
h2 {
  margin-top: 0;
  margin-bottom: 0;
}
h4.card-title {
  font-size: 105%;
}
.remove-form-person-btn {
  margin-top: -2em;
}
@media only screen and (max-width: 550px) {
}
#account-container {
  padding-top: 1rem !important;
}
.alert-icon {
  stroke: red;
}
</style>
