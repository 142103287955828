var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    { attrs: { id: "address-modal", "hide-footer": "" } },
    [
      _c("template", { slot: "modal-title" }, [
        _c("h3", { staticClass: "p-0 m-0" }, [
          _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
        ]),
      ]),
      _c(
        "form",
        {
          ref: "form",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitAddress.apply(null, arguments)
            },
          },
        },
        [
          _c("validation-observer", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ invalid }) {
                  return [
                    _c("address-form", {
                      attrs: {
                        "edit-address": _vm.editAddress,
                        "show-kind": true,
                      },
                      on: { changed: _vm.addressInfoChanged },
                    }),
                    _c("hr"),
                    _c(
                      "b-form-row",
                      { staticClass: "justify-content-center" },
                      [
                        _c("div", { attrs: { id: "btn-submit" } }, [
                          !_vm.loading
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-primary submit-btn-width",
                                  staticStyle: { "pointer-events": "auto" },
                                  attrs: { type: "submit", disabled: invalid },
                                },
                                [
                                  _vm._v(
                                    "\n            Submit Address\n          "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _vm.loading ? _c("ct-centered-spinner") : _vm._e(),
                      ],
                      1
                    ),
                    invalid
                      ? _c(
                          "b-tooltip",
                          {
                            attrs: {
                              target: `btn-submit`,
                              placement: "top",
                              triggers: "hover focus",
                            },
                          },
                          [
                            _vm._v(
                              "\n        Please fill out the rest of the form.\n      "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }