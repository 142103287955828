<template>
  <div v-if="active">
    <b-modal id="edit-general-info-modal" hide-footer hide-header>
      <validation-observer v-slot="{ invalid }">
        <div class="d-block">
          <b-container>
            <b-row>
              <b-col>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="First Name"
                >
                  <b-form-group label="First Name">
                    <b-form-input
                      v-model="editInfo.first_name"
                      type="text"
                      :state="!errors[0] ? null: false"
                    />
                    <b-form-invalid-feedback :state="!errors[0] ? null: false">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Last Name"
                >
                  <b-form-group label="Last Name">
                    <b-form-input
                      v-model="editInfo.last_name"
                      type="text"
                      :state="!errors[0] ? null: false"
                    />
                    <b-form-invalid-feedback :state="!errors[0] ? null: false">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <b-button variant="primary" block @click="updateGeneral(editInfo)" :disabled="invalid">
          Save
        </b-button>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'GeneralInfoModal',
  components: { ValidationObserver, ValidationProvider },
  props: ['bus'],
  async mounted() {
    this.bus.$on('generalInfo', this.open)
  },
  computed: {
    ...mapGetters('account', ['account']),
  },
  methods: {
    ...mapActions('account', ['updatePerson']),
    async updateGeneral(person) {
      try {
        await this.updatePerson({ person: person })
        this.$bvModal.hide('editGeneral')
        this.$emit('complete')
        this.active = false
      } catch(error) {
        this.$bvToast.toast(error.response.data.error.message, {
          title: 'Error',
          variant: 'danger',
        })
      }
    },
    open(generalInfo, name) {
      if (name === 'edit') {
        this.editInfo = generalInfo
      }
      this.active = true
    },
  },
  data() {
    return {
      active: false,
      name: null,
      editInfo: null,
      errors: [],
    }
  },
}
</script>
